import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "toggle" ]
  static targets = [ "container", "rotate" ]

  toggle() {
    this.containerTarget.classList.toggle(this.toggleClass)

    if (this.hasControlTarget) {
      this.controlTarget.classList.toggle("rotate-45") }
    }
}
